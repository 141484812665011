import React, {Fragment} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import SecondImg from '../../assets/elementos-end-to-end-solutions.svg'

const staff = () => {
    return (
        <Fragment>
            <Container  id="staff">
                <Row className="row-width">
                    <Col>
                    <h2 className="text-center">Staff Augmentation </h2>
                    <h3 className="justify-content-md-center text-center">
                    Having the right-sized team is critical to any successful project and, 
                    as they enter new phases of maturity, sometimes it’s necessary to add new 
                    members who are just right for the job. Our IT staffing services provide the 
                    speed, professional acumen and flexibility your team needs to scale quickly and 
                    complete development on time. 
                    </h3>
                    </Col>
                </Row>
                <Row className="justify-content-md-center whatsContent">
                    <Col lg={6} sm={12}>
                        <p>Your Extended Team works as part of your local team, 
                        attending your regular daily meetings and reporting directly to your managers, 
                        proving to be a real competitive advantage for your business. </p> 
                        <p className="mt-5">Our IT staffing services are available for every type of project. No matter 
                            how simple or complex: from prototyping to full-scale development of desktop, 
                            mobile or web applications, testing and systems management. </p>                   
                    </Col>
                    <Col lg={6} sm={12}>
                        <img src={SecondImg} className="img-center" alt="Staff Augmentation "></img>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-5 text-center">
                        <Button variant="primary" href="#getInTouch">What can we do for you?</Button>
                    </Col>                    
                </Row>
            </Container>
        </Fragment>
    )
}

export default staff;
