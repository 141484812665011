import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';

const SoftwarePartner = () => {
    return (
    
                <Container fluid id="softwarePartner">
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col lg={10}>
                                <h4 className="text-center">What to look for in your Nearshore Software Outsourcing Partner</h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center">
                            <Col lg={10}>
                                <h3>Your software outsourcing services provider should:</h3>​
                                <h3 className="list-onshore">
                                    <ul>
                                    <li>Work with effective software development models like Agile and Scrum.</li>
                                    <li>Have transparent workflow processes in place.</li>
                                    <li>Have a great reputation & track record.</li>
                                    <li>Include reliable security protocols.</li>
                                    <li>Have access to the top software engineers in the market.</li>
                                    <li>Speak English fluently and work in a time zone where the range of hours always match.</li>
                                    </ul>
                                </h3>
                            </Col>
                            
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={4}>
                                <Button type="button" class="btn btn-secondary" href="#getInTouch">Contact us</Button>
                                
                            </Col>       
                        </Row>
                    </Container>
                </Container>
           
    )
}

export default SoftwarePartner;