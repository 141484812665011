import React, { Fragment } from "react";
import Menu from "../components/commons/menu";
import CareerBanner from "../components/careers/banner";
import Trusted from "../components/commons/trusted";
import WhyKoiron from "../components/careers/whyKoiron";
import Footer from "../components/commons/footer";
import OpenJobsMini from "../components/careers/openingJobsMini";
import ReadyBuild from "../components/commons/readyBuild";

export const Careers = () => {
  return (
    <Fragment>
      <Menu />
      <CareerBanner />
      <Trusted />
      <WhyKoiron />
      <OpenJobsMini />
      <ReadyBuild />
      <Footer />
    </Fragment>
  );
};

export default Careers;
