import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import imgMainBanner from '../../assets/mainBanner.svg'



const AboutBanner = () => {
    return (
        
            <Container id="mainbanner">
                <Row>
                    <Col lg={8}>
                        <h2>About us</h2>
                        <h1>What moves us</h1>
                        <h3>We believe that digitalization helps the evolution of the world, and integrates people and companies, making their development easier.</h3>

                        <h3>In everything that we do, we bet for innovation and we do it through careful integration of human resources and technology.​</h3>
                        
                    </Col>
                    <Col lg={4}><img src={imgMainBanner} alt="Accelerate your digital transformation" ></img></Col>
                </Row>
                
            </Container>
    )
}

export default AboutBanner;
