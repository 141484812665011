import React from 'react'
import Menu from '../components/commons/menu'
import GetInTouch from '../components/commons/getInTouch'
import ContactReadyBuild from '../components/commons/readyBuildLanding'
import Footer from '../components/commons/footer'







export const ContactPage = () => {


  

    return (
        <div>
            <Menu />
            <GetInTouch />
            <ContactReadyBuild />
            <Footer />
        </div>    
    )
}

export default ContactPage;
