import React, { Fragment, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const GetInTouch = () => {
  const [values, setValues] = useState({
    origin: "Koiron",
    phone: "",
    yourname: "",
    email: "",
    message: "",
  });

  const { yourname, email, message, phone, origin } = values;
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const [responseMessage, setResponseMessage] = useState({
    message: "",
    status: "",
  });

  const sendData = () => {
    setError("");
    setDisabled(true);
    axios
      .post(
        `https://prod-20.brazilsouth.logic.azure.com:443/workflows/4a0de618d958419bb7dc99ba7a3245b7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=QsAL47KtZeSa5He1YXBvXMI4ZsD802JoaITVlC2wXhs`,
        values
      )
      .then(
        (res) => {
          setDisabled(false);
          setError(
            "Thank you very much for your inquiry. We will be in touch with you shortly"
          );
        },
        (error) => {
          setError("There was an error sending. Please try again later.");
          setDisabled(false);
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendData();
    setResponseMessage({
      message: "Sending...",
      status: "sending",
    });
  };

  console.log(values);
  return (
    <Fragment>
      <Container id="getInTouch">
        <Row>
          <Col>
            <h2 className="text-center"> Get in touch</h2>
            <h3 className="text-center">
              {" "}
              Tell us how we can help accelerate your digital transformation
            </h3>
          </Col>
        </Row>
        <Row className="row-width mt-5">
          <Col>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Col} className="input-getIn">
                <Form.Label htmlFor="yourname">Your name *</Form.Label>
                <Form.Control
                  id="yourname"
                  name="yourname"
                  value={yourname}
                  onChange={handleInputChange}
                  required
                  placeholder=" Your name"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter your full name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} className="input-getIn">
                <Form.Label htmlFor="phone">Your phone *</Form.Label>
                <Form.Control
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={handleInputChange}
                  required
                  placeholder=" Your phone"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter your full name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn">
                <Form.Label htmlFor="email">Your work email *</Form.Label>
                <Form.Control
                  required
                  name="email"
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="something@website.com"
                  autoComplete="off"
                  pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter a correct email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn">
                <Form.Label htmlFor="message">How can we help? *</Form.Label>
                <Form.Control
                  required
                  id="message"
                  name="message"
                  as="textarea"
                  value={message}
                  onChange={handleInputChange}
                  rows={5}
                  placeholder="Your message starts with…"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  The field is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit" value="Submit">
                Schedule a Call
              </Button>

              <div className="alert-message">
                <p>{error}</p>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GetInTouch;
