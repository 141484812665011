import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LogoFooter from "../../assets/logo-black.svg";
import Instagram from "../../assets/instagram.svg";
import Facebook from "../../assets/facebook.svg";
import Linkedin from "../../assets/linkedin.svg";
import Twitter from "../../assets/twitter.svg";

const Footer = () => {
  return (
    <Container fluid id="footer">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={4}>
            <img
              src={LogoFooter}
              alt="Koiron"
              className="img-fluid logo-footer"
            ></img>
          </Col>
          <Col lg={2}>
            <p className="titleFooter">Services</p>
            <p>
              <a href="/it-staff-augmentation" target="_blank" rel="noreferrer">
                IT Staff Augmentation
              </a>
            </p>
            <p>
              <a
                href="/dedicate-teams-service"
                target="_blank"
                rel="noreferrer"
              >
                Dedicated Teams
              </a>
            </p>
            <p>
              <a href="/software-services" target="_blank" rel="noreferrer">
                Software Services
              </a>
            </p>
          </Col>
          <Col lg={2}>
            <p className="titleFooter">About </p>
            <p>
              <a href="/about">What we are</a>
            </p>
            <p>
              <a href="/about#ourWork">What we do</a>
            </p>
          </Col>
          <Col lg={2}>
            <p className="titleFooter">Work with us! </p>
            <p>
              <a href="https://careers.koiron.com/">Careers</a>
            </p>
            <p>
              <a href="https://careers.koiron.com/">Apply now</a>
            </p>
          </Col>
          <Col lg={2}>
            <p className="titleFooter">Contact us</p>
            <p>
              <a href="tel:+16084236916">+1 608 423 6916</a>
            </p>
            <p>
              <a href="mailto:info@koiron.com">info@koiron.com</a>
            </p>
          </Col>
        </Row>
        <Row className="disclaimer">
          <Col lg="6">
            <p>Copyright © 2022 Koiron LLC. All Rigths Reserved.</p>
          </Col>
          <Col lg="6" className="redes">
            <ul>
              <li>
                <p>Join Us</p>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/koironcom/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Instagram}
                    alt="Instagram"
                    className="img-fluid"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/KoironCom"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Facebook}
                    alt="Facebook"
                    className="img-fluid"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/koironcom"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Linkedin}
                    alt="Linkedin"
                    className="img-fluid"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/KoironCom"
                  ttarget="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="Twitter" className="img-fluid"></img>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
