import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const OurProcess = () => {
  return (
    <Container fluid id="ourProcess">
      <Container>
        <Row>
          <h4>Our process</h4>
          <Col lg={8}>
            <h3>
              Our approach is based on careful digitalization processes that
              turn into technological solutions for all kind of companies. ​ We
              co-create, alongside with our clients, high-quality software
              products, to address the digital transformation, focusing on
              design and usability.
            </h3>
          </Col>
        </Row>
        <Row className="contentBenefits">
          <Col lg={4}>
            <h5>SCRUM</h5>
            <p>
              <strong>Prioritization - Collaboration - Iteration</strong>
            </p>
            <p>
              Every one of our teams is led by a certified Scrum Master who
              ensures that every project is guided by these principles.
            </p>
          </Col>
          <Col lg={4}>
            <h5>AGILE</h5>
            <p>
              <strong>
                Software that works - Customer involvement - Adaptive to change
              </strong>
            </p>
            <p>
              We guarantee early and continuous delivery of flawless software by
              generating the right environment, keeping our engineers motivated
              and focused, and trusting their judgment and expertise to do the
              job.
            </p>
          </Col>
          <Col lg={4}>
            <h5>DevOps</h5>
            <p>
              <strong>Measurement - Automation - Culture</strong>
            </p>
            <p>
              If we can measure it, we can improve it. If we can automate, we
              can focus on the hard work. If we develop the culture, all this
              will happen on its own.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default OurProcess;
