import React, { Fragment } from 'react';
import MenuSoftwareServices from '../components/commons/menuSoftwareServices';
import MainBannerSoftwareServices from '../components/softwareServices/banner';
import Footer from '../components/commons/footer';
import '../css/softwareServices.css';
import GetInTouch from '../components/commons/getInTouch';
import SoftwareServiceDesc from '../components/softwareServices/softwareServiceDesc';
import BenefitsSoftware from '../components/softwareServices/benefitsSoftware';
import SoftwarePartner from '../components/softwareServices/softwarePartner';
import BenefitsNearshore from '../components/softwareServices/benefitsNearshore';
import ReadyBuildLanding from '../components/commons/readyBuildLanding';

const SoftwareServices = () => {
    return (
        <Fragment>
            <MenuSoftwareServices />
            <MainBannerSoftwareServices />
            <SoftwareServiceDesc />
            <BenefitsSoftware />
            <SoftwarePartner />
            <BenefitsNearshore />
            <GetInTouch />
            <ReadyBuildLanding />
            <Footer />
        </Fragment>
    )
}

export default SoftwareServices
