import React, {Fragment} from 'react'
import Banner from '../components/staff/banner'
import MenuIt from '../components/commons/menuIt'
import Footer from '../components/commons/footer'
import Staff from '../components/staff/staff'
import SoftwareServices from '../components/staff/softwareServices'
import Technologies from '../components/staff/technologies'
import TimeZone from '../components/staff/timeZone'
import Onshore from '../components/staff/onshore'
import Nearshore from '../components/staff/nearshore'
import Company from '../components/staff/company'
import GetInTouch from '../components/commons/getInTouch'
import '../css/staff.css'
import ReadyBuildLanding from '../components/commons/readyBuildLanding'


const ItStaff = () => {
    return (
        <Fragment>
            <MenuIt />
            <Banner /> 
            <Staff />
            <SoftwareServices />
            <Technologies />
            <TimeZone />
            <Onshore />
            <Nearshore />
            <Company />
            <GetInTouch />
            <ReadyBuildLanding />
            <Footer />           
        </Fragment>
    )
}

export default ItStaff;
