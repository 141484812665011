import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import img from '../../assets/benefits-nearshore.svg'

const BenefitsNearshore = () => {
    return (
    
                <Container id="softwareServiceDesc">
                    <Row className="justify-content-md-center">
                        <Col lg={10}>
                            <h4 className="text-center">Benefits of Nearshore Software Development</h4>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center whatIsContent">
                        <Col lg={11}>
                            <Row>
                            <Col lg={6} className="d-flex align-items-center">
                                 <p>Optimize all your resources with Nearshore Software development, quickly incorporating the best professionals for your projects. We share cultural similarities and time zones across the United States and have high English language proficiency and technological expertise, allowing us to establish fluid and clear workflows..</p>
                            </Col>
                            <Col lg={6} className="text-center">
                            <img src={img} class="img-fluid" alt="What is a Dedicated Team" ></img>
                            </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
           
    )
}

export default BenefitsNearshore;