import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';




const OurWork = () => {
    return (
        
            <Container id="ourWork">
               
                    <Row className="justify-content-md-center text-center">
                        
                        <Col lg="8">
                            
                            <h2 class="border-bottom text-center">Our Work</h2>
                            <h3>We develop digital products and services, focused on the users’ best experience.</h3>
                        </Col>
                        
                    </Row>
                    
                
            </Container>
    )
}

export default OurWork;
