import React, {Fragment} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import ThirdImg from '../../assets/elementos-ilustracion-3.svg'

const softwareServices = () => {
    return (
        <Fragment>
            <Container fluid id="software-services">
                <Container>
                    <Row>
                        <Col lg={6} sm={12} className="pr-5">
                            <h4>Software Services</h4>
                            <p>Software outsourcing services involve working with a third-party 
                                team with specialized skills and expertise. Today, businesses worldwide 
                                work with software outsourcing companies to access experienced software 
                                engineers boasting a range of tech skills from UX design to Blockchain Consulting. </p> 
                            <p>Simply put, outsourcing software developers allows organizations to develop high-quality 
                                software products in a cost-efficient way. From smart customization of pre-developed 
                                platforms to full-cycle custom solutions, Possumus Koiron is ready to assist you by 
                                delivering reliable and custom software solutions.
                                We are a world-class team of professionals entirely dedicated to driving the digital 
                                transformation of our clients.  </p>
                                <Button variant="primary" className="mt-5" href="#getInTouch">See how we can help</Button>
                        </Col>
                        <Col lg={6} sm={12}>
                            <div  className="card-white">
                                <img src={ThirdImg} alt="Software Services<"></img>
                                <h4>Companies eligible for our service </h4>
                                <p>Companies eligible for our services are startups and medium-sized companies. 
                                    And this is so because these are the companies which, in their evolution and progress, 
                                    need to complete or expand their work teams with the dynamics and management 
                                    culture of their own size.  These are aspects that we share and experience very well. </p>
                            
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>            
        </Fragment>
    )
}

export default softwareServices;
