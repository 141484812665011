import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Careers = () => {
  return (
    <Container id="careers">
      <Row className="justify-content-md-left text-left">
        <Col>
          <p className="sup-title text-uppercase">Careers</p>
          <h4 className="mb-4">What to join our teams?</h4>
          <Button variant="primary" href="https://careers.koiron.com/">
            View your openings job
          </Button>
          <Button variant="secondary" href="https://careers.koiron.com/">
            Send resume
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Careers;
