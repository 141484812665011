import React, {Fragment} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Java from '../../assets/icon-tech/logo-java.svg'
import Net from '../../assets/icon-tech/logo-net.svg'
import ReactLogo from '../../assets/icon-tech/logo-react.svg'
import Ios from '../../assets/icon-tech/group.svg'
import Android from '../../assets/icon-tech/group-2.svg'
import Node from '../../assets/icon-tech/logo-node.svg'
import Angular from '../../assets/icon-tech/logo-angular.svg'


const technologies = () => {
    return (
        <Fragment>
            <Container fluid id="technologies">
                <Container>
                    <Row>
                        <Col  className="row-width">
                            <h2 className="text-center">Technologies</h2>
                            <p className="text-center">Although our services can be provided in any of the available technologies,
                                 70% of our database is composed of professionals specialized in: </p>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col className="mt-5">
                            <ul>
                                <li><img src={Java} alt="Java"></img></li>
                                <li><img src={Net} alt=".Net"></img></li>
                                <li><img src={ReactLogo} alt="React"></img></li>                                
                                <li><img src={Ios} alt="Ios"></img></li>
                                <li><img src={Android} alt="Android"></img></li>
                                <li><img src={Node} alt="Node"></img></li>
                                <li><img src={Angular} alt="Angular"></img></li>
                            </ul>

                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={4}>
                            <Button variant="secondary" href="#getInTouch">Connect with us</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    )
}

export default technologies
