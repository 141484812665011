import React from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import LogoHeader from "../../assets/logo.svg";
const Menu = () => {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={LogoHeader} alt="Koiron" className="LogoHeader"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className=" justify-content-end">
            {/* <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item href="/software-engineering">
                Software Engineering
              </NavDropdown.Item>
              <NavDropdown.Item href="/dedicate-teams-service">
                Dedicated Teams
              </NavDropdown.Item>
              <NavDropdown.Item href="/devops-sre">DevOps SRE</NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link href="/about">About</Nav.Link>

            <NavDropdown title="Careers" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://careers.koiron.com/">Work with us</NavDropdown.Item>
              <NavDropdown.Item href="https://careers.koiron.com/">
                Open Jobs
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/contact" className="contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
