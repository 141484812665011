import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import imgMainBanner from '../../assets/mainBanner-software-services.svg'



const MainBannerSoftwareServices = () => {
    return (
        
            <Container fluid id="mainbannerSoftware">
                <Container id="mainbanner">
                    <Row>
                        <Col lg={8}>
                            <h1>Work with the top talent</h1>
                            <h3>Software outsourcing services involve working with a third-party team with specialist skills and expertise.</h3>
                            <div className="cta">
                                <Button variant="primary" href="#softwareServiceDesc">Get started</Button>
                                <Button variant="secondary" href="#getInTouch">Contact us</Button>
                            </div>  
                        </Col>
                        <Col lg={4}><img src={imgMainBanner} class="img-fluid" alt="Accelerate your digital transformation" ></img></Col>
                    </Row>
                </Container>
            </Container>
    )
}

export default MainBannerSoftwareServices;
