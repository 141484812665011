import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import imgTeamVsTime from '../../assets/teamvstime.svg'

const TeamVsTime = () => {
    return (
    
                <Container fluid id="teamVsTime">
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col lg={10}>
                                <h4 className="text-center">Dedicated Team vs. Dedicated Time</h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-left row1">
                            <Col lg={7}>
                                <h3>Our Dedicated Teams hiring plans are based on participation models that are applied according to your needs and define the bases of collaboration that we will provide to you and your company.</h3>
                                <h3>The participation model that we select, always guarantees the level of control, flexibility, professionalism, and responsibility of the teams involved in the development of your project.</h3>​
                            </Col>
                            <Col lg={2}>
                                <img src={imgTeamVsTime} class="img-fluid" alt="Dedicated Team vs. Dedicated time" ></img>
                            </Col>
                        </Row>

                        <Row className="justify-content-md-center">
                            <Col lg={10}>
                                <h4 className="text-center">Dedicated Team</h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center">
                            <Col lg={10} className="lista">
                                <p>The Dedicated Team model is regularly used in long-term projects, which evolve with requirements, objectives and scopes from time to time. They enhance the companies' own resources by incorporating high-performance equipment, according to project needs and are regularized in monthly payments, based on the size of the team available.</p>
                                <p>Our Dedicated Team service is prepared to manage your project and take it to the next level, with the development and application of an exclusive team, which delivers the best results and multiple analytical feedback options for the client. </p>​
                            </Col>
                        </Row>

                        <Row className="justify-content-md-center">
                            <Col lg={10}>
                                <h4 className="text-center">Time Spent</h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center">
                            <Col lg={10} className="lista">
                                <p>In the "Dedicated Time" model you hire the time and effort that our developers dedicate to your project, working on specific functions. It is a very flexible and high-performance model, which includes start and end dates in stages (for example, weekly, monthly, semi-annual).</p>
                                <p>In this model, you propose the scope of our services and monitor all applications through reports, to ensure that each development is relevant. Your participation is fundamental in this model, since a close bond is required that will allow you to be involved in the decision-making processes.</p> 
                                <p>The deadlines are defined as flexible, specifying each established or new requirement, to reach the optimal completion of the project. Their participation, management and control are essential to avoid wasted time and budget inflation.</p>
                            </Col>
                        </Row> 
                    </Container>                   
                </Container>
           
    )
}

export default TeamVsTime;