import React, {Fragment} from 'react'
import { Container, Row, Col } from 'react-bootstrap'


const onshore = () => {
    return (
        <Fragment>
            <Container fluid id="onshore">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="text-center">Onshore, nearshore or offshore: </h2>
                            <p className="text-center">What Dedicated Team hiring model is suitable for your project?</p>
                            <p className="text-center">We help define the model that best suits your company and projects: </p>
                            <div className="list-onshore">
                                <ul>
                                    <li>Onshore: Equipment located in the same country or region.</li>

                                    <li>Offshore: Teams located in another country with a different time zone.</li>

                                    <li>Nearshore: Equipment located in a neighbouring country or within walking distance.</li>
                                </ul>
                            </div>
                            <p className="text-center row-width">In the software development 
                            projection, you do not need to generate new equipment 
                                in your structure, unless you wish it. You can incorporate 
                                today the ideal
                                 Dedicated Team, with all the required skills, exclusive to 
                                 develop and scale 
                                 your project, both in near and far countries. 
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} sm={12} >
                            <div className="card">
                                <h4 className="text-center">Onshore</h4>
                                <h5 className="mb-4">Software development</h5>
                                <p className="text-center">Company locate in California and your outsourcing partner in 
                                    New Jersey. You’re working in different states but in the 
                                    same country.</p>
                            </div>                            
                        </Col>
                        <Col lg={4} sm={12}>
                            <div className="card">
                                <h4 className="text-center">Nearshore</h4>
                                <h5 className="mb-4">Software development</h5>
                                <p className="text-center">US company can outsource in Canada, Mexico and Argentina.</p>
                            </div>
                        </Col>
                        <Col lg={4} sm={12}>
                            <div className="card">
                                <h4 className="text-center">Offshore</h4>
                                <h5 className="mb-4">Software development</h5>
                                <p className="text-center">American company hires outsourcing services in Asia 
                                including India, Singapore, Vietnam, etc.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    )
}

export default onshore
