import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import imgTeam1 from "../../assets/team-1.png";
import imgTeam2 from "../../assets/team-2.png";
import "../../css/readyBuildLandings.css";

const ReadyBuildLanding = () => {
  return (
    <Container fluid id="readyBuild" className="readyBuildLandings">
      <Container>
        {/* <Row lg={10} className="justify-content-md-center text-left bg_white">
                        <Col lg={4}>
                            <h4>Ready to build your software?</h4>
                        </Col> 
                        <Col lg={4}>
                            <div className="clearfix d-lg-flex">
                                <img src={imgTeam1} className="img-fluid float-left" alt="Software Services"></img>
                                <div>
                                    <h3>Sebastián Delfino</h3>
                                    <p className="p-1">Sales Consultant</p>
                                    <p className="p-2">
                                        T: +1 608 423 6916
                                       <br /> 
                                       E:<a href="mailto:sebastian.delfino@possumus.tech">sebastian.delfino@possumus.tech</a>
                                    </p>
                                </div>
                            </div>
                        </Col> 
                        <Col lg={4}>
                        <div className="clearfix d-lg-flex">
                                <img src={imgTeam2} className="img-fluid float-left" alt="Software Services"></img>
                                <div>
                                    <h3>Melisa Grasso</h3>
                                    <p className="p-1">
                                        Business Development
                                    </p>
                                    <p className="p-2">
                                        T: +1 608 423 6916
                                       <br /> 
                                       E:<a href="mailto:melisa.grasso@possumus.techh">melisa.grasso@possumus.tech</a>
                                    </p>
                                </div>
                            </div>
                        </Col>                        
                    </Row> */}
        <Row className="address text-left">
          <ul class="list-group list-group-horizontal">
            <li class="list-group-item flex-fill">
              <h3>Denver</h3>
              <p>+1 608 423 6916</p>
              <p>6063 S Lamar Dr Littleton,</p>
              <p>CO 80123</p>
              <p>US</p>
            </li>
            <li class="list-group-item flex-fill">
              <h3>Buenos Aires AR</h3>
              <p>+54 11 6829 1849</p>
              <p>Tte. Gral. Juan D. Perón 698.</p>
              <p>Ciudad de Buenos Aires. </p>
              <p>C1038AAN</p>
              <p>Argentina</p>
            </li>
            <li class="list-group-item flex-fill">
              <h3>San Rafael AR (HQ)</h3>
              <p>+54 260 442 0484</p>
              <p>Agustín Álvarez 25,</p>
              <p>San Rafael,  </p>
              <p>Mendoza</p>
              <p>Argentina</p>
            </li>
            <li class="list-group-item flex-fill">
              <h3>General Alvear AR</h3>
              <p>+54 261 622 4096</p>
              <p>Sarmiento y Centenario,</p>
              <p>Bowen, General Alvear,</p>
              <p>Mendoza</p>
              <p>Argentina</p>
            </li>
          </ul>
        </Row>
      </Container>
    </Container>
  );
};

export default ReadyBuildLanding;
