import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import isoKoiron from '../../assets/iso.svg'



const ValueProposal = () => {
    return (
        
            <Container id="valueProposal">
               
                    <Row className="justify-content-md-center text-center">
                        
                        <Col lg="8">
                            <img src={isoKoiron} alt="Koiron" className="img-fluid iso"></img>
                            <h2 class="border-bottom text-center">Value Proposal</h2>
                            <h3>Our approach is based on careful digitalization processes that turn into technological solutions for all kind of companies. ​</h3>

                            <h3>We co-create, alongside with our clients, high-quality software products, to address the digital transformation, focusing on design and usability. </h3>
                        </Col>
                        
                    </Row>
                    
                
            </Container>
    )
}

export default ValueProposal;
