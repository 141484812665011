import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';



const ReadyBuild = () => {
    return (
        
            <Container fluid id="readyBuild">
                <Container >
                    <Row className="justify-content-md-center text-center">
                        <Col lg={12}>
                            <h1 >Ready to build your software?</h1>
                            <h3>Get in touch</h3>
                            <Button variant="yellow" href="/contact">Contact Us</Button>
                        </Col>
                        
                    </Row>
                    <Row className="address text-left" >
                    <ul class="list-group list-group-horizontal">
                        <li class="list-group-item flex-fill">
                            <h3>Denver</h3>
                            <p>+1 608 423 6916</p>
                            <p>6063 S Lamar Dr Littleton,</p>
                            <p>CO 80123</p>
                            <p>US</p>
                        </li>
                        <li class="list-group-item flex-fill">
                            <h3>Buenos Aires AR</h3>
                            <p>+54 11 6829 1849</p>
                            <p>Tte. Gral. Juan D. Perón 698.</p>
                            <p>Ciudad de Buenos Aires. </p>
                            <p>C1038AAN</p>
                            <p>Argentina</p>
                        </li>
                        <li class="list-group-item flex-fill">
                            <h3>San Rafael AR (HQ)</h3>
                            <p>+54 260 442 0484</p>
                            <p>Agustín Álvarez 25,</p>
                            <p>San Rafael,  </p>
                            <p>Mendoza</p>
                            <p>Argentina</p>
                        </li>
                        <li class="list-group-item flex-fill">
                            <h3>General Alvear AR</h3>
                            <p>+54 261 622 4096</p>
                            <p>Sarmiento y Centenario,</p>
                            <p>Bowen, General Alvear,</p>
                            <p>Mendoza</p>
                            <p>Argentina</p>
                        </li>
                        
                    </ul>

                    </Row>
                 </Container>
            </Container>
    )
}

export default ReadyBuild;