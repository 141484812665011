import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home } from "../pages/Home";
import DedicatedTeams from "../pages/DedicatedTeams";
import ItStaff from "../pages/ItStaff";
import SoftwareServices from "../pages/SoftwareServices";
import About from "../pages/About";
import Careers from "../pages/Careers";
import ContactPage from "../pages/Contact";
import Position from "../pages/OpenPositions";
import ReactGA from "react-ga";
import WorkWithUs from "../pages/WorkWithUs";

export const AppRouter = () => {
  useEffect(() => {
    ReactGA.initialize("UA-166248567-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/dedicate-teams-service"
          component={DedicatedTeams}
        />
        <Route exact path="/it-staff-augmentation" component={ItStaff} />
        <Route exact path="/software-services" component={SoftwareServices} />
        <Route exact path="/about" component={About} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/opening-jobs" component={WorkWithUs} />
        <Route exact path="/open-position/:id" component={Position} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
