import React, {Fragment} from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const company = () => {
    return (
        <Fragment>            
            <Container id="company">
                <Row>
                    <Col lg={4}>
                        <h3 className="mb-5">Different language? Not a problem for us</h3>
                        <p>Communication is key in the development of projects 
                            of any kind, but specially when teams are located in 
                            different countries. According to the English Proficiency 
                            Index elaborated by EF, Argentina ranks first in Latin
                             America and is the only country having a score of
                              "High proficiency" in the region. Having high 
                              proficiency means Argentines can make a presentation 
                              at work, understand TV shows or read a newspaper without 
                              any problem. This means that when you hire a dev team 
                              or just a developer in Argentina, you can expect them 
                              to be part of your team meetings and communicate progress 
                              or issues as if they were part of your in-house team. </p>
                    </Col>
                    <Col lg={4}>
                            <h3 className="mb-5">But what about tech skills? </h3>
                            <p>So far you might be thinking "yeah, those guys speak good English, 
                            have a timezone similar to ours, but how do they rank on 
                            technology skills?". The Coursera Global Skills Index unites 
                            Coursera’s deep expertise in data and education to highlight 
                            the world’s top trending career skills. In the Technology category, 
                            Argentina ranks 1st in the world. We are at the top of the list 
                            of 15 countries that have cutting edge skills on Technology,
                             and we are the only Latin American country in the cutting edge list. 
                            </p>
                            <p>Some of the competencies that Coursera lists within the 
                            Technology category are Computer Networking, Operating 
                            Systems, Databases and Software Engineering.  </p>
                    </Col>
                    <Col lg={4}>
                            <h3 className="mb-5">Culture</h3>
                            <p>Even though there's a ~9000km (~5,600mi) distance between us, 
                                Argentines and Americans are very similar in terms of culture.
                                 And I'm not just talking about food, drinks, art or sports... 
                                 we are very similar in terms of working culture, specially
                                  when talking about the IT industry. Working environments 
                                  are pretty much the same ones you can find in any of the 
                                  major IT companies in the US. </p>
                            <p>Due to our European roots, we share a similar mindset and 
                                lifestyle with American people. This is key in understanding 
                                your project requirements and it allows us to share our point 
                                of view about your app features. We can see your projects 
                                both as developers as well as potential users because of 
                                our cultural similarity.  </p>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default company
