import React, { useEffect, useState, Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

import Menu from "../components/commons/menu";
import ReadyBuild from "../components/commons/readyBuild";
import Footer from "../components/commons/footer";

import MarkdownView from "react-showdown";

const Position = ({ match }) => {
  const [posiciones, setposiciones] = useState([]);
  const [loadedData, setLoadedData] = useState(false);
  const idjob = match.params.id;
  const description = posiciones.Detalles;

  useEffect(() => {
    axios
      .get(`https://admin.possumus.tech/posiciones-abiertas/${idjob}`)
      .then((res) => {
        setposiciones(res.data);
        setLoadedData(true);
        setValues({
          origin: "Possumuss",
          position: res.data.Titulo,
          yourname: "",
          lastname: "",
          email: "",
          phone: "",
          url: "",
          cv: "",
        });
      })
      .catch(function (error) {
        window.location = "/careers";
      });
  }, []);

  console.log(posiciones.Titulo);

  const [values, setValues] = useState({
    origin: "Koiron",

    yourname: "",
    lastname: "",
    email: "",
    phone: "",
    url: "",
    cv: "",
  });

  const { yourname, lastname, email, phone, url, cv, origin, position } =
    values;
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const [responseMessage, setResponseMessage] = useState({
    message: "",
    status: "",
  });

  const sendData = () => {
    setError("");
    setDisabled(true);
    axios
      .post(
        `https://prod-23.brazilsouth.logic.azure.com:443/workflows/b611515ae5a34062abffc60d2f8a3287/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=TdHWkTPbWzvqDBXPKoJwlbz9UFJItyiGxa1eJ_SVtiY`,
        values
      )
      .then(
        (res) => {
          setDisabled(false);
          setError(
            "Thank you for your interest in being part of us! We will contact you shortly."
          );
        },
        (error) => {
          setError("There was an error sending. Please try again later.");
          setDisabled(false);
        }
      );
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LdTDi0dAAAAAANWnEgiFWcoJKIawAJOUtn16O86", {
          action: "submit",
        })
        .then(function (token) {
          // Send form value as well as token to the server
          sendData();
          setResponseMessage({
            message: "Sending...",
            status: "sending",
          });
        });
    });
  };

  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };

  const changeClass = (id) => {
    const ceve = document.getElementById(id);
    ceve.className = " contentDropzoneOk";
  };

  const removeClass = (id) => {
    const ceve = document.getElementById(id);
    ceve.classList.remove("icontentDropzoneOk");
    ceve.classList.add("contentDropzone");
  };

  const handleChangeStatus = async ({ meta, file }, status) => {
    console.log(status, meta);
    /*setValues({
      ...values,
      cv: file,
    });*/
    console.log(status);

    if (status === "done") {
      const base64 = await convertBase64(file);
      values["cv"] = base64;
    }

    if (status === "headers_received") {
      changeClass("curr");
    } else if (status === "removed") {
      removeClass("curr");
    }
  };

  console.log(values);

  return (
    <Fragment>
      <Menu />
      <Container fluid id="bannerOpeningJobs">
        <Container>
          <h1 class="text-center">{posiciones.Titulo}</h1>
        </Container>
      </Container>
      <Container id="resumeJobs">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/opening-jobs#openingJobs">Open Jobs</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {posiciones.Titulo}
            </li>
          </ol>
        </nav>
        <h2>Job Description</h2>
        <p>
          Location: <strong>{posiciones.Location}</strong>
        </p>
        <p>
          Commitment: <strong>{posiciones.Commitment}</strong>
        </p>
        <MarkdownView markdown={description} className="descriptionJob" />
      </Container>
      <Container id="getInTouch">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h4>Apply for the position</h4>
            <p>
              Leave us your information here so that we can get in touch with
              you:
            </p>
            <Form onSubmit={handleSubmit} id="formJobs">
              <Form.Group as={Col} className="input-getIn" controlId="yourname">
                <Form.Label>First Name *</Form.Label>
                <Form.Control
                  required
                  id="yourname"
                  name="yourname"
                  value={yourname}
                  onChange={handleInputChange}
                  placeholder="First Name"
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn" controlId="lastName">
                <Form.Label>Last name *</Form.Label>
                <Form.Control
                  required
                  id="lastname"
                  name="lastname"
                  value={lastname}
                  onChange={handleInputChange}
                  placeholder="Last name"
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} className="input-getIn" controlId="email">
                <Form.Label> email *</Form.Label>
                <Form.Control
                  required
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Email"
                ></Form.Control>
              </Form.Group>
              <Form.Group
                as={Col}
                className="contentDropzone"
                controlId="files"
                id="curr"
              >
                <Form.Label>Add resume *</Form.Label>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  inputContent="Drag and drop files here"
                  maxFiles={1}
                  id="cv"
                  name="cv"
                  required
                />
                <div id="toast"></div>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn" controlId="phone">
                <Form.Label> Phone *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={handleInputChange}
                  placeholder="Phone"
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn" controlId="url">
                <Form.Label>Linkedin Profile</Form.Label>
                <Form.Control
                  type="text"
                  id="url"
                  name="url"
                  value={url}
                  onChange={handleInputChange}
                  placeholder="https://"
                ></Form.Control>
              </Form.Group>

              <Button variant="primary" type="submit" value="submit">
                Send
              </Button>
              <div className="alert-message">
                <p>{error}</p>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <ReadyBuild />
      <Footer />
    </Fragment>
  );
};

export default Position;
