import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import imgWhatIf from '../../assets/software-services-des.svg'



const SoftwareServiceDesc = () => {
    return (
    
                <Container id="softwareServiceDesc">
                    <Row className="justify-content-md-center">
                        <Col lg={10}>
                            <h2 className="text-center">Software Services</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center text-center">
                        <Col lg={10}>
                            <h3>Software outsourcing services allows you to work with a team of developers with specialized skills and experience, optimizing full-cycle development processes or high-quality software updates, in a cost-effective manner.</h3>​
                        </Col>
                        
                    </Row>

                    <Row className="justify-content-md-center whatIsContent">
                        <Col lg={11}>
                            <Row>
                            <Col lg={7}>
                                 <p>Our software outsourcing service forms a strategic alliance for reliable and safe developments. We provide high-performance experiences.​</p>
                            </Col>
                            <Col lg={4} className="text-center">
                            <img src={imgWhatIf} class="img-fluid" alt="Software Services" ></img>
                            </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            
                            <Button variant="secondary" href="#getInTouch">Contact us</Button>
                        </Col>       
                    </Row>
                </Container>
           
    )
}

export default SoftwareServiceDesc;