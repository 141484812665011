import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import img from '../../assets/benefits-software.svg'



const BenefitsSoftware = () => {
    return (
    
                <Container fluid id="benefitsSoftware">
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col lg={12}>
                                <h4 className="text-center">Benefits of Software Development Outsourcing</h4>
                            </Col>
                        </Row>

                        <Row className="justify-content-md-center">
                            <Col lg={12}>
                                <Row>
                                <Col lg={7}>
                                    <p>You can access today to work with the most talented software engineers in the industry. From the experience of our development teams, you will get the best results: </p>
                                    <p>
                                        <ul>
                                            <li>Start configured: Immediate or scheduled kick off.</li> 
                                            <li>Increase in productivity and optimization of performance of your projects.</li> 
                                            <li>Reduction of application and development risks.</li> 
                                            <li>Increased security and resolution of situations.</li> 
                                            <li>Dynamic application experiences, professional environment, flexible business partner.</li> 
                                        </ul>
                                    </p>
                                    <p>Software Development Outsourcing is developed under a specific schedule, increasing your offer and resources of talents and skills, and reducing all the risks involved in your line of work. It is a specialized, accessible service that reduces any gap between your product and the market, optimizing your budget and profitability.</p>
                                </Col>
                                <Col lg={5} className="text-center d-flex">
                                <img src={img} class="img-fluid" alt="Benefits of Software Development Outsourcing" ></img>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                
                                <Button variant="primary" href="#getInTouch">Contact us</Button>
                            </Col>       
                        </Row>
                    </Container>
                </Container>
           
    )
}

export default BenefitsSoftware;