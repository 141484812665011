import React, {Fragment} from 'react'
import Menu from '../components/commons/menu';
import AboutBanner from '../components/about/banner';
import Trusted from '../components/commons/trusted';
import ValueProposal from '../components/about/valueProposal';
import Deck from '../components/commons/downloadDeck';
import Footer from '../components/commons/footer';
import OurWork from '../components/about/ourWork';
import ReadyBuild from '../components/commons/readyBuild';



export const About = () => {


  

    return (
        <Fragment>
            <Menu />
            <AboutBanner />
            <Trusted />
            <ValueProposal />
            <OurWork />
            <Deck />
            <ReadyBuild />
            <Footer />
        </Fragment>    
    )
}

export default About;
