import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import isoKoiron from "../../assets/iso.svg";

const Turning = () => {
  return (
    <Container fluid id="turning">
      <Container>
        <Row>
          <Col lg={2}>
            <img src={isoKoiron} alt="Koiron" className="img-fluid"></img>
          </Col>
          <Col lg={5} className="titleTurnig text-center">
            <h4>We’re turning 22</h4>
            <h5>2000/2022</h5>
          </Col>
          <Col lg={5} className="textTurnig text-center">
            <p>
              Since 2000 we have been developing digital products and services,
              focused on the best user experience.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Turning;
