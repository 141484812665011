import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Deck = () => {
  // const [koironBrochure, setkoironBrochure] = useState([]);

  // useEffect(() => {
  //   axios.get(`https://admin.possumus.tech/brochures`).then((res) => {
  //     setkoironBrochure(res.data.Koiron);
  //   });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const brochureKoiron = "https://admin.possumus.tech" + koironBrochure.url;
  return (
    <Container id="deck">
      <Row className="justify-content-md-center text-center">
        <Col lg={12}>
          <h2 className="title-deck">Download our deck and let’s talk</h2>
          <Button
            variant="secondary"
            href="deck/Brochure_Koiron.pdf"
            target="_blank"
          >
            Download
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Deck;
