import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import imgWhats from '../../assets/whatsMoves.jpg'



const WhatMoves = () => {
    return (
        
            
                <Container id="whatMoves">
                    <Row className="justify-content-md-center">
                        <Col lg={5}>
                            <h2 className="border-bottom text-center">What moves us</h2>
                        </Col>
                        
                    </Row>
                    <Row className="justify-content-md-center text-center">
                        <Col lg={8}>
                            <h3>We believe that digitalization helps the evolution of the world, and integrates people and companies, making their development easier.</h3>​
                            <h3>In everything that we do, we bet for innovation and we do it through careful integration of human resources and technology.</h3>
                        </Col>
                        
                    </Row>

                    <Row className="justify-content-md-center whatsContent">
                        <Col lg={9}>
                            <Row>
                            <Col lg={4}>
                            <img src={imgWhats} alt="Banco Santander" ></img>
                            </Col>
                            <Col lg={8}>
                                 <p>Our approach is based on careful digitalization processes that turn into technological solutions for all kind of companies. ​</p>
                                <p>We co-create, alongside with our clients, high-quality software products, to address the digital transformation, focusing on design and usability. </p>
                            </Col>

                            </Row>
                        </Col>
                        
                    </Row>
                </Container>
           
    )
}

export default WhatMoves;