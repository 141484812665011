import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import imgMainBanner from '../../assets/mainBanner.svg'



const MainBanner = () => {
    return (
        
            <Container id="mainbanner">
                <Row>
                    <Col lg={8}>
                        <h1>Accelerate your digital transformation</h1>
                        <h3>Software outsourcing services involve working with a third-party team with specialist skills and expertise.</h3>
                        <div className="cta">
                            <Button variant="primary" href="#services">Services</Button>
                            <Button variant="secondary" href="/about" >About</Button>
                        </div>  
                    </Col>
                    <Col lg={4}><img src={imgMainBanner} alt="Accelerate your digital transformation" ></img></Col>
                </Row>
                
            </Container>
    )
}

export default MainBanner;
