import React, {Fragment} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import RankingTable from '../../assets/bitmap-copy-3.png'
import Map from '../../assets/group-13.png'


const timeZone = () => {
    return (
        <Fragment>
            <Container fluid id="timeZone">
                <Container>
                    <Row>
                        <h2 className="text-center">Time zone alignment </h2>
                    </Row>
                    <Row>
                        <Col lg={6} sm={12} className="mt-5">
                            <p>Possumus Koiron developers always match a range of hours with their North American peers, 
                                regardless of their time zone. So they can attend virtual meetings and calls, 
                                respond to emails and work alongside clients for seamless integration throughout the day.
                            </p>
                            <p> 
                                This facilitates real-time project management and efficient feedback 
                                cycles for faster delivery rates. </p>
                            <div className="table-ranking">
                                <h6>EF EPI Rankings</h6>
                                <img src={RankingTable} alt="EF EPI Rankings"></img>
                            </div>
                        </Col>
                        <Col lg={6} sm={12}>
                            <img src={Map} alt="Time Zone"></img>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid id="timeZone-gray">
                <Container>
                    <Row>
                        <Col lg={3} sm={12}>
                            <h4 className="text-center">Time zone</h4>
                        </Col>
                        <Col lg={9} sm={12}>
                            <p>Argentina is located in UTC-3, meaning we can easily 
                            adapt and share most of our working day with your team, whether you are based
                             on the east or the west coast. </p>
                        </Col>
                    </Row>   
                    <Row className="justify-content-center">
                        <Col lg={4}>
                            <Button variant="primary" href="#getInTouch">Put Top Talent to work for you</Button>
                        </Col>
                    </Row>                 
                </Container>
            </Container>
        </Fragment>
    )
}

export default timeZone;
