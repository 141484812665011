import React, {Fragment} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import FourImg from '../../assets/nearshore.svg'

const nearshore = () => {
    return (
        <Fragment>
            <Container fluid id="nearshore">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="text-center">Nearshore</h2>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col lg={6} sm={12}>
                            <h3>Even though many companies keep it behind curtains, 
                                everybody in the industry knows that during the past 
                                decade the nearshore software development business 
                                grew considerably. The top reason that pushes companies 
                                in the US or Europe to outsource their software development 
                                efforts to other countries is money. Hiring a team in-house 
                                is way more expensive than hiring a team in other (cheaper) 
                                countries. But... (there's always a but) more often than not, 
                                cheaper means poor quality. </h3>
                        </Col>
                        <Col lg={6} sm={12}>
                            <img src={FourImg} className="img-center mt-5" alt="Nearshore"></img>
                        </Col>
                    </Row>
                    <Row className="row-width mt-5">
                        <Col>
                            <h2 className="text-center mb-5 mt-5">Low cost, top quality</h2>
                            <p className="text-center">During the past decade, Argentina showed that it's a reliable 
                                and cost effective option for outsourcing. The low value of 
                                the Argentines currency compared to the US Dollar allows our 
                                IT companies to have the most competitive prices in the global 
                                market. When you pair low prices with all the other reasons 
                                listed in this article, you'll understand why Argentina is 
                                the top destination to outsource any software development 
                                work and save lots on costs.  </p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={4} >
                            <Button variant="secondary" href="#">Let's talk</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    )
}

export default nearshore
