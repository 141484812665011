import React from 'react'
import { Container, Row } from 'react-bootstrap';
import santander from '../../assets/clientes-santander.svg';
import hyundai from '../../assets/clientes-hyundai.svg';
import nissan from '../../assets/clientes-nissan.svg';
import yamaha from '../../assets/clientes-yamaha.svg';
import bayer from '../../assets/clientes-bayer.svg';
import andreani from '../../assets/clientes-andreani.svg';





const Trusted = () => {
    return (
        
            <Container fluid id="trusted">
                <Container>
                    <Row>
                    <ul class="list-group list-group-horizontal">
                        <li class="list-group-item ">Trusted by</li>
                        <li class="list-group-item "><img src={santander} alt="Banco Santander" ></img></li>
                        <li class="list-group-item "><img src={hyundai} alt="Hyundai" ></img></li>
                        <li class="list-group-item "><img src={nissan} alt="Nissan" ></img></li>
                        <li class="list-group-item "><img src={yamaha} alt="Yamaha" ></img></li>
                        <li class="list-group-item "><img src={bayer} alt="Bayer" ></img></li>
                        <li class="list-group-item "><img src={andreani} alt="Andreani" ></img></li>
                    </ul>
                        
                    </Row>
                </Container>
            </Container>
    )
}

export default Trusted;