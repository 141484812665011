import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import imgWhatIf from '../../assets/dedicated-whatif.svg'



const WhatIs = () => {
    return (
    
                <Container id="whatIs">
                    <Row className="justify-content-md-center">
                        <Col lg={10}>
                            <h2 className="text-center">What is a Dedicated Team?</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center text-center">
                        <Col lg={10}>
                            <h3>Dedicated Team are teams of specialist developers, with key roles, dedicated to the optimization and resolution of their projects. </h3>​
                        </Col>
                        
                    </Row>

                    <Row className="justify-content-md-center whatIsContent">
                        <Col lg={12}>
                            <Row>
                            <Col lg={6}>
                                 <p>Each team has a PM, Software Engineers, Quality Control Engineers and specific roles that your project demands. Each management is carried out jointly by the Scrum Master or the Engineering Manager of Possumus Koiron and the Product Owner of the client. ​</p>
                                 <p>A Dedicated Team works autonomously using agile methodology, reporting the permanent status of each project. It optimizes times in sprints that efficiently solve the backlog (or prioritized list of requirements), with the necessary versatility to always refocus the project objectives.  </p>
                                 <p>In Possumus Koiron we gather the best customized team to cover your resource and commercial needs, providing efficient and high profitability solutions for your projects.</p>
                            </Col>
                            <Col lg={6}>
                            <img src={imgWhatIf} class="img-fluid" alt="What is a Dedicated Team" ></img>
                            </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-center">
                            
                            <Button variant="primary" href="#getInTouch">Contact us</Button>
                        </Col>       
                    </Row>
                </Container>
           
    )
}

export default WhatIs;