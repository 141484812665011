import React, {Fragment} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import FirstImg from '../../assets/elementos-bd-ilustracion.svg'

const ItStaffBanner = () => {
    return (
        <Fragment>
            <Container fluid id="mainbanner-it">
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <h1>Low cost, <br></br> top quality</h1>
                            <h3>Remove the barriers to growth by
                                scaling your team fast with top-notch Latin American IT talent.
                            </h3>
                            <div className="cta">
                                <Button variant="primary" href="#staff">Get started</Button>
                                <Button variant="secondary" href="#getInTouch">Contact us</Button>
                            </div>  
                        </Col>
                        <Col lg={6} sm={12}>
                            <img src={FirstImg} className="img-center" alt="Accelerate your digital transformation" ></img>
                        </Col>
                    </Row>
                </Container>
            </Container>
            
        </Fragment>
    )
}

export default ItStaffBanner;
