import React, {Fragment} from 'react'
import MenuDT from '../components/commons/menuDedicatedTeams';
import MainBannerDedicatedTeam from '../components/dedicatedTeams/banner';
import Footer from '../components/commons/footer';
import WhatIs from '../components/dedicatedTeams/whatIs';
import TeamVsTime from '../components/dedicatedTeams/teamVsTime';
import '../css/dedicatedTeams.css';
import GetInTouch from '../components/commons/getInTouch';
import ReadyBuildLanding from '../components/commons/readyBuildLanding';

const DedicatedTeams = () => {
    return (
        <Fragment>
            <MenuDT />
            <MainBannerDedicatedTeam />
            <WhatIs />
            <TeamVsTime />
            <GetInTouch />
            <ReadyBuildLanding />
            <Footer />
        </Fragment>
    )
}

export default DedicatedTeams
