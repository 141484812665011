import React, { Fragment } from "react";
import Menu from "../components/commons/menu";
import MainBanner from "../components/home/banner";
import Trusted from "../components/commons/trusted";
import WhatMoves from "../components/home/whatMoves";
import Turning from "../components/home/turning";
import Deck from "../components/commons/downloadDeck";
import ReadyBuild from "../components/commons/readyBuild";
import Footer from "../components/commons/footer";
import Careers from "../components/home/careers";

export const Home = () => {
  return (
    <Fragment>
      <Menu />
      <MainBanner />
      <Trusted />
      <WhatMoves />
      <Turning />
      <Deck />
      <Careers />
      <ReadyBuild />
      <Footer />
    </Fragment>
  );
};

export default Home;
