import React from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap';
import LogoHeader from '../../assets/logo.svg'
const MenuIt = () => {
    return (
        
    
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand href="/"><img src={LogoHeader} alt="Koiron" className="LogoHeader"></img>  <span>IT Staff Augmentation</span></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav className=" justify-content-end">
                
                    <Nav.Link href="tel:+1 608 423 6916">+1 608 423 6916</Nav.Link>
                    <Nav.Link href="/contact" className="contact">Contact</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MenuIt;
