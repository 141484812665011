import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const CareerBanner = () => {
  return (
    <Container id="mainbanner">
      <Row>
        <Col lg={8}>
          <h1>Work with us</h1>
          <h2>We would like you to join us!</h2>
          <h3>
            We believe that digitalization helps the evolution of the world, and
            integrates people and companies, making their development easier.​
            In everything that we do, we bet for innovation and we do it through
            careful integration of talent and technology.
          </h3>
          <div className="cta"></div>
        </Col>
        <Col lg={4}></Col>
      </Row>
    </Container>
  );
};

export default CareerBanner;
