import React, { Fragment } from "react";
import Menu from "../components/commons/menu";
import Trusted from "../components/commons/trusted";
import WhyPossumus from "../components/careers/whyPossumus";
import Footer from "../components/commons/footer";
import OpenJobs from "../components/careers/openingJobs";
import OurProcess from "../components/careers/OurProcess";
import OpenBanner from "../components/careers/bannerOpenJobs";

export const WorkWithUs = () => {
  return (
    <Fragment>
      <Menu />
      <OpenBanner />
      <Trusted />
      <WhyPossumus />
      <OpenJobs />
      <OurProcess />
      <Footer />
    </Fragment>
  );
};

export default WorkWithUs;
