import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";

const OpenJobsMini = () => {
  const [posiciones, setposiciones] = useState([]);
  const [loadedData, setLoadedData] = useState(false);

  useEffect(() => {
    axios.get(`https://admin.possumus.tech/posiciones-abiertas`).then((res) => {
      setposiciones(res.data.reverse());
      setLoadedData(true);
      console.log(posiciones);
    });
  }, []);

  const koironPosition = posiciones.filter(
    (posiciones) => posiciones.Empresa === "Koiron"
  );

  const urlJobs = "/open-position/";

  return (
    <Container fluid id="openingJobs">
      <Container>
        <h4>Open jobs</h4>
        <Row>
          {loadedData ? (
            koironPosition.map((posicion, key, array) =>
              0 === key || 1 === key || 2 === key ? (
                <Col lg={4} key={posicion.id}>
                  <a key={posicion.id} href={urlJobs + posicion.id}>
                    <Card style={{ width: "20rem" }}>
                      <Card.Body>
                        <Card.Title>
                          <h5>{posicion.Titulo}</h5>
                        </Card.Title>
                        <Card.Text>
                          <p>
                            Location: <strong>{posicion.Location}</strong>
                          </p>
                          <p>
                            Commitment: <strong>{posicion.Commitment}</strong>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </a>
                </Col>
              ) : (
                ""
              )
            )
          ) : (
            <p>Cargando...</p>
          )}
        </Row>
        <Row className="mt-4">
          <Col className="text-center mt-4">
            <Button variant="secondary" href="/opening-jobs">
              See Moore
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default OpenJobsMini;
